import {
  apiGet,
  apiPatch,
  apiPost
} from 'src/core/interceptors/axios-instance';
import { apiUploadS3 } from 'src/core/interceptors/axios-instance-s3';

export const getUserData = async () => {
  const response = await apiGet('api/v1/user', null);
  return response.data;
};

export const updateUserData = async (payload, params) => {
  const response = await apiPatch('api/v1/user', payload, { params: params });
  return response.data;
};

export const addAddress = async (payload) => {
  const response = await apiPost('api/v1/user/address', null, payload);
  return response.data;
};

export const getPresignedUrl = async (payload, params) => {
  const response = await apiPost('api/v1/document/presigned', null, payload, {
    params: params
  });
  return response.data;
};

export const uploadOnS3 = async (url, payload) => {
  const response = await apiUploadS3(url, payload);
  return response.data;
};

export const createDocument = async (payload, params) => {
  const response = await apiPost('api/v1/document', null, payload, {
    params: params
  });
  return response.data;
};

export const getResume = async () => {
  const response = await apiGet('api/v1/user/resume', null);
  return response.data;
};
