import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';

import {
  Button,
  CircularProgress,
  TextField,
  showErrorMessage
} from '../../shared/components/index';
import resendEmailLogo from '../../assets/images/resend_email.svg';
import {
  resendOnboardingEmail,
  selectResendEmailDetail
} from './service/resendEmail.service';
import ResendEmailSuccess from './components/ResendEmailSuccess';
import { useRouter } from 'src/providers/RouterProvider';
import * as ROUTES from '../../shared/constants/routes';

const ResendEmail = () => {
  //Constants
  const { t } = useTranslation('english');
  const dispatch = useDispatch<any>();
  const { routeTo } = useRouter();

  //Form
  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({ mode: 'onChange' });

  //Redux
  const { resendEmailDetails, loading, error } = useSelector(
    selectResendEmailDetail
  );

  //State
  const [showResendEmailSuccess, setShowResendEmailSuccess] = useState(false);

  //useEffects
  useEffect(() => {
    if (resendEmailDetails && Object.keys(resendEmailDetails).length) {
      if (resendEmailDetails?.is_email_verified) {
        showErrorMessage(resendEmailDetails?.message, {
          theme: 'colored',
          position: 'bottom-center',
          autoClose: 3000
        });
      } else {
        setShowResendEmailSuccess(true);
      }
    }
  }, [resendEmailDetails]);

  useEffect(() => {
    if (error) {
      routeTo(ROUTES.RESEND_EMAIL_ERROR);
    }
  }, [error]);

  const onSubmit = (data) => {
    dispatch(resendOnboardingEmail(data));
  };

  const getEmailValidationRules = () => ({
    required: t('emailRequiredErrMsg'),
    maxLength: {
      value: 50,
      message: t('emailMaxLengthErrMsg', { maxLength: 50 })
    },
    pattern: {
      value: /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: t('invalidEmailErrMsg')
    }
  });

  //HTML
  return (
    <>
      {showResendEmailSuccess ? (
        <ResendEmailSuccess />
      ) : (
        <Box sx={{ p: isMobile ? 5 : 7 }} className="flex-column-center">
          <Box sx={{ mb: 7 }}>
            <img src={resendEmailLogo} alt="Resend Email" />
          </Box>
          <Box className="text-h2 text-grey-2 textalign">
            {t('resendEmail.title1')}
          </Box>
          <Box sx={{ mb: 5 }} className="text-h2 text-grey-2 textalign">
            {t('resendEmail.title2')}
          </Box>
          <Box sx={{ mb: 1 }} className="text-h3 text-grey-2 textalign">
            {t('resendEmail.question1')}
          </Box>
          <Box
            sx={{ mb: 5 }}
            className="text-medium-regular text-grey-2 textalign"
          >
            {t('resendEmail.answer1')}
          </Box>
          <Box sx={{ mb: 1 }} className="text-h3 text-grey-2 textalign">
            {t('resendEmail.question2')}
          </Box>
          <Box
            sx={{ mb: 5 }}
            className="text-medium-regular text-grey-2 textalign"
          >
            {t('resendEmail.answer2')}
          </Box>
          <Box sx={{ mb: 1 }} className="text-h3 text-grey-2 textalign">
            {t('resendEmail.question3')}
          </Box>
          <Box
            sx={{ mb: 5, px: isMobile ? 0 : 30 }}
            className="text-medium-regular text-grey-2 textalign"
          >
            {t('resendEmail.answer3')}
          </Box>
          <Box sx={{ px: isMobile ? 0 : 10 }} className="w-100">
            <form>
              <Box sx={{ px: isMobile ? 0 : 25 }} className="w-100">
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={getEmailValidationRules()}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('emailAddress')}
                      fullWidth
                      required
                      error={!!errors.email}
                      helperText={errors.email?.message || ' '}
                    />
                  )}
                />
              </Box>
              <Box className="flex-column-center">
                {loading ? (
                  <CircularProgress sx={{ mb: 2 }} color="primary" />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!isValid}
                    btnText={t('resendEmail.resendEmailBtnTxt')}
                    onClick={handleSubmit(onSubmit)}
                  />
                )}
              </Box>
            </form>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ResendEmail;
