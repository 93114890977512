import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress } from '@mui/material';

import { SimpleDialog } from 'src/shared/components/modals/SimpleDialog';
import { Button } from 'src/shared/components/button/Button';
import { deleteUser } from '../apis/common';
import { showErrorMessage } from 'src/shared/components/toaster/Toast';
import { useAuth } from 'src/providers/AuthguardContext';
import { logEvent } from 'firebase/analytics';
import { firebaseAnalytics } from 'src/firebaseNotifications/firebase';

export interface DeleteAccountDialogProps {
  open: boolean;
  onClose: () => void;
  unSubscribeTopic: () => void;
}

const DeleteAccountDialog = ({
  open,
  onClose,
  unSubscribeTopic
}: DeleteAccountDialogProps) => {
  // Constants
  const { t } = useTranslation('english');
  const { logout } = useAuth();

  // State Variables
  const [loading, setLoading] = useState(false);

  // Methods
  const handleDelete = async () => {
    logEvent(firebaseAnalytics, 'delete_account_click_yes');
    setLoading(true);
    try {
      await unSubscribeTopic();
      const response = await deleteUser();
      if (response && Object.keys(response)?.length > 0) {
        setLoading(false);
        onClose();
        logout();
      }
    } catch (err) {
      setLoading(false);
      showErrorMessage('Error in deleting user', {
        theme: 'colored',
        position: 'bottom-center',
        autoClose: 3000
      });
    }
  };

  const closeDeleteDialog = () => {
    logEvent(firebaseAnalytics, 'delete_account_click_no');
    onClose();
  };

  return (
    <SimpleDialog
      open={open}
      fullWidth
      maxWidth="xs"
      model_title={
        <Box className="flex-basic-center" sx={{ mt: 1 }}>
          <Box className="text-large-semibold">
            {t('delete_account.delete_account_title')}
          </Box>
        </Box>
      }
      model_content={
        <Box>
          <Box className="text-medium-regular text-center">
            <Box sx={{ mb: 2 }}>
              {t('delete_account.delete_account_confirmation')}
            </Box>
            <Box>{t('delete_account.are_you_sure')}</Box>
          </Box>
          <Box className="flex-column-center" sx={{ mt: 3 }}>
            <Button
              sx={{ mb: 1 }}
              width="button-w-280"
              btnText={t('delete_account.no')}
              variant="contained"
              onClick={closeDeleteDialog}
            />
            <Box>
              {loading ? (
                <CircularProgress sx={{ mb: 2 }} color="primary" />
              ) : (
                <Button
                  sx={{ mb: 1 }}
                  width="button-w-280"
                  btnText={t('delete_account.yes_delete')}
                  variant="text"
                  onClick={handleDelete}
                />
              )}
            </Box>
          </Box>
        </Box>
      }
    />
  );
};

export default DeleteAccountDialog;
