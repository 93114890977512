export const USER_SERVICE_URL = process.env.REACT_APP_USER_SERVICE_URL;
export const AUTH_SERVICE_URL = process.env.REACT_APP_AUTH_SERVICE_URL;

export const ACCESS_TOKEN = `ACCESS_TOKEN`;
export const FORGOT_PASSWORD_TOKEN = `FORGOT_PASSWORD_TOKEN`;

export const BUILD_NUMBER = '0.3.2';

export const LOGIN = import('src/auth/login/Login');
export const SIGNUP = import('src/auth/login/SignUp');

export const DASHBOARD = import('src/modules/dashboard/Dashboard');
export const JOBS = import('src/modules/jobs/Jobs');
export const ERROR_PAGE = import('src/modules/ErrorPage/ErrorPage');
export const MY_IDENTITY = import(
  'src/modules/dashboard/components/MyIdentity'
);
export const MY_RESUME = import('src/modules/dashboard/components/MyResume');

export const DELETE_ACCOUNT = import(
  'src/modules/delete-account/DeleteAccount'
);

export const COMPLETED = 'completed';
export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';
export enum statusLable {
  COMPLETED = 'completed',
  PENDING = 'pending',
  FAILED = 'failed'
}

export const ITEMS_PER_PAGE_LIST = [5, 10, 20, 50, 100];

export const GPLACES_ADDRESS_CONSTRAINTS = {
  COUNTRY: 'country',
  STATE: 'administrative_area_level_1',
  CITY: 'locality',
  ZIP: 'postal_code'
};

export const STATES_LIST = [
  'alabama',
  'alaska',
  'american samoa',
  'arizona',
  'arkansas',
  'california',
  'colorado',
  'connecticut',
  'delaware',
  'district of columbia',
  'federated states of micronesia',
  'florida',
  'georgia',
  'guam',
  'hawaii',
  'idaho',
  'illinois',
  'indiana',
  'iowa',
  'kansas',
  'kentucky',
  'louisiana',
  'maine',
  'marshall islands',
  'maryland',
  'massachusetts',
  'michigan',
  'minnesota',
  'mississippi',
  'missouri',
  'montana',
  'nebraska',
  'nevada',
  'new hampshire',
  'new jersey',
  'new mexico',
  'new york',
  'north carolina',
  'north dakota',
  'northern mariana islands',
  'ohio',
  'oklahoma',
  'oregon',
  'palau',
  'pennsylvania',
  'puerto rico',
  'rhode island',
  'south carolina',
  'south dakota',
  'tennessee',
  'texas',
  'utah',
  'vermont',
  'virgin islands',
  'virginia',
  'washington',
  'west virginia',
  'wisconsin',
  'wyoming'
];

export const FILE_SIZE = {
  DOCUMENT_FILE_SIZE: 10000000 //10mb
};

export const DOCUMENT_MIME_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

export const FILE_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'video/mp4',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain',
  'image/jpeg',
  'image/png',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export const IMAGE_MIME_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

export const DOCUMENT_TYPE_LABELS = ['PDF', 'Doc', 'Docx'];

export const MAX_CHAR_LIMIT = {
  BASIC_CHAR_LENGTH: 20,
  CHAR_LENGTH_THIRTY: 30,
  CHAR_LENGTH_TWO_HUNDRED: 200,
  CHAR_LENGTH_THREE_HUNDRED: 300
};

export const PASSWORD_STRENGTH_LABEL_BY_SCORE: Record<number, string> = {
  0: 'weak',
  1: 'weak',
  2: 'fair',
  3: 'good',
  4: 'strong'
};

export const TRUST_TAP_APP_URL = 'https://trusttap.onelink.me/dHVQ/bp12bakt';

export const PRIVACY_POLICY = 'https://support.trusttap.com/privacy';

export const TRELLIS_DOCUMENT = 'https://support.trusttap.com/about';

export const TERMS_OF_SERVICE = 'https://support.trusttap.com/terms';

export const SUPPORT_EMAILS = {
  HELP_ASSISTANCE: 'trusttap@trellishelp.zendesk.com'
};

export const REGEX = {
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  NUMBER_DECIMAL:
    /^(?:\d{1,2}(?:\.\d{1,2})?|100(?:\.0{1,2})?|0(?:\.\d{1,2})?)$/,
  NUMBER_INTEGER: /^(?:\d*[1-9]\d*|)$/,
  TEXT_ONLY: /^[a-zA-Z ]*$/,
  PASSWORD:
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])(?!.*\s).{8,}$/,
  JSON: /^[\],:{}\s]*$|^"(.|\\[\\"/bfnrt])*"$/
};

export const MEGABYTE_CONVERTER = 1000000;

export const FIELD_TYPE = {
  TEXT: 'text',
  RADIO: 'radio',
  EMAIL: 'email',
  SWITCH: 'switch',
  SELECT: 'select',
  REGEX: 'regex',
  PASSWORD: 'password',
  CHECKBOX: 'checkbox',
  TEXTAREA: 'textarea',
  NUMBER_ONLY: 'number_only',
  INTEGER_ONLY: 'integer_only',
  MULTI_SELECT: 'multi-select',
  AUTOCOMPLETE: 'autocomplete',
  CHECKBOX_GROUP: 'checkbox_group'
};

export const DATE_TIME: any = {
  YEAR: 'y',
  MONTH: 'M',
  DAY: 'd',
  HOUR: 'h',
  MINUTE: 'm',
  SECOND: 'now'
};

export const JOB_OPPORTUNITIES_POSTED_DAYS_BEFORE = [
  {
    id: '1',
    label: '1 day'
  },
  {
    id: '3',
    label: '3 days'
  },
  {
    id: '7',
    label: '7 days'
  },
  {
    id: '14',
    label: '14 days'
  }
];

export const JOBS_WITHIN_MILES = [
  {
    id: '5',
    label: '5 miles'
  },
  {
    id: '15',
    label: '15 miles'
  },
  {
    id: '30',
    label: '30 miles'
  },
  {
    id: '50',
    label: '50 miles'
  }
];
