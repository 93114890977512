import { Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import onboardingSuccess from '../../../assets/images/success.svg';
import { useTranslation } from 'react-i18next';
import { clearOnboardingDetails } from '../service/onboarding.service';
import { useDispatch } from 'react-redux';
import { useRouter } from 'src/providers/RouterProvider';
import * as ROUTES from '../../../shared/constants/routes';

const OnboardingSuccess = () => {
  //Constants
  const { t } = useTranslation('english');
  const dispatch = useDispatch<any>();
  const { routeTo } = useRouter();

  useEffect(() => {
    dispatch(clearOnboardingDetails());
  }, []);

  // Methods
  const navigateTo = () => {
    routeTo(ROUTES.RESEND_EMAIL);
  };

  //HTML
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box
          sx={{ py: 10, px: isMobile ? 3 : 0 }}
          className="flex-column-center text-center"
        >
          <Box sx={{ mb: 6 }}>
            <img src={onboardingSuccess} alt="OnbardingSuccess" />
          </Box>
          <Box sx={{ mb: 1 }} className="text-h2 text-grey-2">
            {t('onboarding.greatJob')}
          </Box>
          <Box sx={{ mb: 3 }} className="text-h2 text-grey-2">
            {t('onboarding.jobSearchPortalReady')}
          </Box>
          <Box sx={{ mb: 10 }} className="text-medium-regular text-grey-2">
            {t('onboarding.successSubTitle')}
          </Box>
          <Box sx={{ mb: 6 }} className="text-h2 text-grey-2">
            {t('onboarding.whatsNext')}
          </Box>
          <Box sx={{ mb: 2 }} className="text-medium-regular text-grey-2">
            {t('onboarding.whatsNextPoint1')}
          </Box>
          <Box sx={{ mb: 2 }} className="text-medium-regular text-grey-2">
            {t('onboarding.whatsNextPoint2')}
          </Box>
          <Box className="text-medium-regular text-grey-2">
            {t('onboarding.whatsNextPoint3')}
            <span
              className="text-primary text-medium-semibold cursorPointer"
              onClick={navigateTo}
            >
              {t('onboarding.dontHaveEmailLink')}
            </span>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OnboardingSuccess;
