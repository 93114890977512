import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store/reducer';
import { getAlerts, subscribeTopic, getIp } from '../apis/commonApis';
import {
  AlertResponse,
  TopicSubscriptionDetails,
  IpResponseType
} from '../models/commonInterface';

interface CommonService {
  loading: boolean;
  error: boolean;
  errorObj: Object;
  alertDetails: AlertResponse;
  topicSubscriptionDetails: TopicSubscriptionDetails;
  newAlertFlag: boolean;
  clientTokenForAlert: string;
  ipResponse: IpResponseType;
  showMobileAppBanner: boolean;
}

const initialState: CommonService = {
  loading: false,
  error: false,
  errorObj: {},
  alertDetails: <AlertResponse>{},
  topicSubscriptionDetails: <TopicSubscriptionDetails>{},
  ipResponse: <IpResponseType>{},
  newAlertFlag: false,
  clientTokenForAlert: '',
  showMobileAppBanner: true
};

export const selectCommonDetails = ({ commonData }: RootState) => ({
  loading: commonData?.loading,
  error: commonData?.error,
  errorObj: commonData?.errorObj,
  alertDetails: commonData?.alertDetails,
  newAlertFlag: commonData?.newAlertFlag,
  topicSubscriptionDetails: commonData?.topicSubscriptionDetails,
  clientTokenForAlert: commonData?.clientTokenForAlert,
  ipResponse: commonData?.ipResponse,
  showMobileAppBanner: commonData?.showMobileAppBanner
});

export const getIpAddress = createAsyncThunk(
  'common/getIpAddress',
  async () => {
    const result = await getIp();
    return result;
  }
);

export const getAlertList = createAsyncThunk(
  'common/getAlertList',
  async () => {
    const result = await getAlerts();
    return result;
  }
);

export const subscribeToTopic = createAsyncThunk(
  'common/subscribeToTopic',
  async (payload: Object) => {
    const result = await subscribeTopic(payload);
    return result;
  }
);

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setNewAlertFlag: (state, action) => {
      state.newAlertFlag = action.payload;
    },
    setClientTokenForAlert: (state, action) => {
      state.clientTokenForAlert = action.payload;
    },
    setShowMobileAppBanner: (state, action) => {
      state.showMobileAppBanner = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIpAddress.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getIpAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.ipResponse = action.payload;
        }
      })
      .addCase(getIpAddress.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getAlertList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAlertList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.alertDetails = action.payload;
        }
      })
      .addCase(getAlertList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(subscribeToTopic.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(subscribeToTopic.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.topicSubscriptionDetails = action.payload;
        }
      })
      .addCase(subscribeToTopic.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  }
});

export const {
  setNewAlertFlag,
  setClientTokenForAlert,
  setShowMobileAppBanner
} = commonSlice.actions;

export default commonSlice.reducer;
