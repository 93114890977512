import { Button, Scrollbar } from 'src/shared/components/index';
import { Box, useTheme, useMediaQuery, Drawer } from '@mui/material';
import { useContext } from 'react';
import SidebarMenu from './sidebar-menu/SidebarMenu';
import trustTapLogo from '../../assets/images/trusttap_logo.svg';
import trustTapCircle from '../../assets/images/trust_tap_circle.png';

import { SidebarContext } from 'src/providers/SidebarContext';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { TRUST_TAP_APP_URL } from 'src/shared/constants/constants';
import { logEvent } from 'firebase/analytics';
import { firebaseAnalytics } from 'src/firebaseNotifications/firebase';

const Sidebar = () => {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('sm'));
  const closeSidebar = () => toggleSidebar();
  const classes = isLg ? 'large SidebarWrapper ' : 'SidebarWrapper';
  const wrapperclass = 'sidebarwrapperLight';
  const finalclass = `${classes} ${wrapperclass}`;
  const { t } = useTranslation('english');

  // Methods
  const navigateTo = () => {
    logEvent(firebaseAnalytics, 'download_app');
    window.open(TRUST_TAP_APP_URL, '_blank');
  };

  return (
    <>
      {!isMobile ? (
        <Box className={finalclass}>
          <Scrollbar>
            <Box m={4} className="flex-basic-center">
              <img src={trustTapLogo} />
            </Box>
            <SidebarMenu />
          </Scrollbar>
        </Box>
      ) : (
        <Drawer
          className="sidebarDrawer"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <Box className="SidebarWrapperlightTheme SidebarWrapper">
            <Scrollbar>
              <Box m={4} className="flex-basic-center">
                <img src={trustTapLogo} />
              </Box>
              <SidebarMenu />
              <Box className="flex-basic-center">
                <Box className="social-section text-medium-semibold text-white text-center">
                  <Box>
                    <img src={trustTapCircle} />
                  </Box>
                  <Box sx={{ mt: 0.5 }}>
                    {t('sidebar.download_app_message1')}
                  </Box>
                  <Box sx={{ mb: 0.5 }}>
                    {t('sidebar.download_app_message2')}
                  </Box>
                  <Box sx={{ pt: 2, px: 3 }}>
                    <Box className="get-our-app-section">
                      <Button
                        btnText={t('sidebar.get_our_app')}
                        variant="outlined"
                        onClick={navigateTo}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Scrollbar>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default Sidebar;
