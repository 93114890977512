import { Button as MuiButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ButtonProps } from './services/buttonInterface';

const useStyles = makeStyles({
  iconOnly: {
    '& .MuiButton-startIcon': {
      marginRight: 0,
      marginLeft: 0
    }
  }
});

export const Button = ({
  btnText,
  icon,
  btnClass,
  width,
  ...props
}: ButtonProps) => {
  const classes = useStyles();
  return (
    <>
      <MuiButton
        startIcon={icon}
        component="span"
        sx={{
          padding: btnText ? '6px 16px' : '6px'
        }}
        className={clsx({
          [btnClass]: btnText,
          [classes.iconOnly]: !btnText,
          [width]: !!width
        })}
        {...props}
      >
        {btnText}
      </MuiButton>
    </>
  );
};
