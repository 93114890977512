import { apiGet, apiPost } from 'src/core/interceptors/axios-instance';
import { AUTH_SERVICE_URL } from 'src/shared/constants/constants';

export const setPassword = async (payload: Object) => {
  const response = await apiPost('api/user', AUTH_SERVICE_URL, payload);
  return response.data;
};

export const validateUserToken = async (payload: Object) => {
  const response = await apiGet('api/v1/user/validate', {
    params: payload
  });
  return response.data;
};
