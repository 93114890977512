import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import React, { useState } from 'react';
import { useRoutes } from 'react-router-dom';
import router from './router/router';
import './scss/commonStyle.scss';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { Provider } from 'react-redux';
import { themeCreator } from '../src/core/theme/base';
import { store, persistor } from '../src/store/configure-store';
import { AuthProvider } from './providers/AuthguardContext';
import { CustomRouterProvider } from './providers/RouterProvider';
import { PersistGate } from 'redux-persist/integration/react';

const App = () => {
  const content = useRoutes(router);
  const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CustomRouterProvider>
          <AuthProvider>
            <StylesProvider injectFirst>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <CssBaseline />
                  {content}
                </LocalizationProvider>
              </ThemeProvider>
            </StylesProvider>
          </AuthProvider>
        </CustomRouterProvider>
      </PersistGate>
    </Provider>
  );
};
export default App;
