import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setPassword, validateUserToken } from '../api/setPasswordApi';
import {
  SetPasswordResultType,
  ValidateTokenResponse
} from '../models/setPassword';
import { showErrorMessage } from '../../../shared/components';
import { RootState } from 'src/store/reducer';

interface OnboardingDetails {
  loading: boolean;
  error: boolean;
  errorObj: Object;
  userDetail: SetPasswordResultType;
  validateTokenDetail: ValidateTokenResponse;
}

const initialState: OnboardingDetails = {
  loading: false,
  error: false,
  errorObj: {},
  userDetail: <SetPasswordResultType>{},
  validateTokenDetail: <ValidateTokenResponse>{}
};

export const selectSetPasswordDetail = ({ setPasswordData }: RootState) => ({
  loading: setPasswordData?.loading,
  error: setPasswordData?.error,
  errorObj: setPasswordData?.errorObj,
  userDetail: setPasswordData?.userDetail,
  validateTokenDetail: setPasswordData?.validateTokenDetail
});

export const userSignUp = createAsyncThunk(
  'setPassword/userSignUp',
  async (payload: Object) => {
    const result = await setPassword(payload);
    return result;
  }
);

export const validateUser = createAsyncThunk(
  'setPassword/validateUser',
  async (payload: Object) => {
    const result = await validateUserToken(payload);
    return result;
  }
);

export const setPasswordSlice = createSlice({
  name: 'setPassword',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearResults() {}
  },
  extraReducers: (builder) => {
    builder
      .addCase(userSignUp.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(userSignUp.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.userDetail = action.payload;
        }
      })
      .addCase(userSignUp.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(validateUser.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(validateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.validateTokenDetail = action.payload;
        }
      })
      .addCase(validateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
  }
});

export const { clearResults } = setPasswordSlice.actions;

export default setPasswordSlice.reducer;
