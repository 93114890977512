import axios, { AxiosRequestConfig } from 'axios';
import { USER_SERVICE_URL } from '../../shared/constants/constants';
import {
  clearStorage,
  getAccessToken,
  getForgotPasswordToken
} from 'src/shared/utils/storage';
import { showErrorMessage } from 'src/shared/components/toaster/Toast';
import * as ROUTES from '../../shared/constants/routes';

export interface TAxiosCustomConfig {
  variables?: string;
  setLoading?: (res: true | false) => void;
}
export interface Config extends AxiosRequestConfig {
  custom?: TAxiosCustomConfig;
}

const axiosInstance = axios.create({
  baseURL: USER_SERVICE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const errorHandler = (error: any) => {
  showErrorMessage(`${error ? error : 'Something went wrong'}`, {
    theme: 'colored',
    position: 'bottom-center',
    autoClose: 5000
  });
  return Promise.reject({ ...error });
};

axiosInstance.interceptors.request.use(
  (request) => {
    const access_token = getAccessToken();
    const forgot_password_token = getForgotPasswordToken();
    if (access_token) {
      request.headers['Authorization'] = `Bearer ${access_token}`;
    }
    if (forgot_password_token) {
      request.headers['Authorization'] = `Bearer ${forgot_password_token}`;
    }
    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 ||
      error.response.status === 403 ||
      error.response.status === 400 ||
      error.response.status === 404 ||
      error.response.status === 409
    ) {
      if (
        error?.response?.config?.url !== 'api/v1/user/resend-email' &&
        error?.response?.config?.url !== 'api/v1/alert/unsubscribe'
      ) {
        errorHandler(error.response.data?.detail);
      }
      if (error.response.status === 401) {
        clearStorage();
        errorHandler('Session expired');
        window.location.href = `${ROUTES.LOGIN}`;
      }
    } else if (
      error.response.status === 500 ||
      error.response.status === 502 ||
      error.response.status === 504
    ) {
      errorHandler(error.response.data?.detail);
    }
    return Promise.reject(error);
  }
);

export async function apiGet<T = any>(
  resource: string,
  config?: AxiosRequestConfig
) {
  return axiosInstance.get<T>(`${USER_SERVICE_URL}${resource}`, config);
}

export async function apiPost<T = any>(
  resource: string,
  base?: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  return axiosInstance.post<T>(
    base ? `${base}${resource}` : resource,
    data,
    config
  );
}

export async function apiPut<T = any>(
  resource: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  return axiosInstance.put<T>(`${USER_SERVICE_URL}${resource}`, data, config);
}

export async function apiPatch<T = any>(
  resource: string,
  data?: any,
  config?: AxiosRequestConfig
) {
  return axiosInstance.patch<T>(`${USER_SERVICE_URL}${resource}`, data, config);
}

export async function apiUpload<T = any>(
  resource: string,
  data?: any,
  config?: AxiosRequestConfig,
  progressCallback?: (progress: number) => void
) {
  return axiosInstance.post<T>(`${USER_SERVICE_URL}${resource}`, data, {
    ...config,
    headers: {
      ...config?.headers,
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (progressEvent) => {
      const uploadPercentage = parseInt(
        Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        ).toString()
      );
      if (progressCallback) {
        progressCallback(uploadPercentage);
      }
    }
  });
}

export async function apiUploadS3<T = any>(
  resource: string,
  data?: any,
  progressCallback?: (progress: number) => void
) {
  return axiosInstance.post<T>(resource, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (progressEvent) => {
      const uploadPercentage = parseInt(
        Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        ).toString()
      );
      if (progressCallback) {
        progressCallback(uploadPercentage);
      }
    }
  });
}

export async function apiDelete<T = any>(
  resource: string,
  base?: string,
  config?: AxiosRequestConfig
) {
  return axiosInstance.delete<T>(`${base}${resource}`, config);
}

export default axiosInstance;
