import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import resendEmailError from '../../../assets/images/resend-email-error.svg';
import { Button } from '../../../shared/components/index';
import { useRouter } from 'src/providers/RouterProvider';
import * as ROUTES from '../../../shared/constants/routes';
import { resetState } from '../service/resendEmail.service';
import { useDispatch } from 'react-redux';

const ResendEmailError = () => {
  //Constants
  const { t } = useTranslation('english');
  const { routeTo } = useRouter();
  const dispatch = useDispatch<any>();

  //useEffect
  useEffect(() => {
    dispatch(resetState());
  }, []);

  return (
    <Box sx={{ p: isMobile ? 5 : 7 }} className="flex-column-center">
      <Box sx={{ mb: 5 }}>
        <img src={resendEmailError} alt="Resend Email" />
      </Box>
      <Box sx={{ mb: 1 }} className="text-h2 text-grey-2 textalign">
        {t('resendEmailError.title1')}
      </Box>
      <Box sx={{ mb: 7 }} className="text-h2 text-grey-2 textalign">
        {t('resendEmailError.title2')}
      </Box>
      <Box
        sx={{ mb: 7, maxWidth: '50%' }}
        className="text-medium-regular text-grey-2 textalign"
      >
        {t('resendEmailError.description')}
      </Box>
      <Box sx={{ mb: 5 }} className="text-medium-regular text-grey-2 textalign">
        <Button
          btnText={t('resendEmailError.btnText')}
          variant="contained"
          onClick={() => routeTo(ROUTES.ONBOARDING)}
        />
      </Box>
    </Box>
  );
};

export default ResendEmailError;
