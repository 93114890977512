import { useEffect, useRef, useState } from 'react';
import { Box, Divider, IconButton, Popover } from '@mui/material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';

import { useAuth } from 'src/providers/AuthguardContext';
import {
  CircularProgress,
  SimpleDialog,
  Typography
} from 'src/shared/components/index';
import {
  clearSetPasswordDetails,
  selectAuthDetail
} from 'src/auth/login/service/auth.service';
import ResetPasswordDialog from 'src/auth/login/ResetPasswordDialog';
import { unsubscribeTopic } from 'src/shared/apis/commonApis';
import { selectDashboard } from 'src/modules/dashboard/service/dashboard.service';
import { selectCommonDetails } from 'src/shared/services/common.service';
import HelpAndAssistance from 'src/shared/components/help-and-assistance/HelpAndAssistance';
import DeleteAccountDialog from 'src/modules/common/components/DeleteAccountDialog';
import { clearResults } from 'src/modules/set-password/service/setPassword.service';
import { logEvent } from 'firebase/analytics';
import { firebaseAnalytics } from 'src/firebaseNotifications/firebase';

const useStyles = makeStyles({
  popOverStyle: {
    '& .MuiPaper-root': {
      borderRadius: '0 !important'
    }
  }
});

const HeaderUserbox = () => {
  const { logout } = useAuth();
  const ref = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('english');
  const classes = useStyles();
  const dispatch = useDispatch<any>();
  const options = [
    {
      id: 'reset-password',
      icon: 'icon-reset-password',
      label: 'resetPasswordSubtitle'
    },
    {
      id: 'help-assistance',
      icon: 'icon-help-assistance',
      label: 'set_password.helpAndAssistance'
    },
    {
      id: 'delete-account',
      icon: 'icon-delete',
      label: 'delete_account.delete_account_subtitle'
    },
    {
      id: 'logout',
      icon: 'icon-logout',
      label: 'logout'
    }
  ];

  const [isOpen, setOpen] = useState<boolean>(false);
  const [user, setUser] = useState<{ name: string; avatar: string }>(
    {} as { name: string; avatar: string }
  );
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
  const [showBackdropLoader, setShowBackdropLoader] = useState(false);
  const [showHelpandAssistanceDialog, setShowHelpandAssistanceDialog] =
    useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);

  const { userDetails } = useSelector(selectAuthDetail);
  const { userData } = useSelector(selectDashboard);
  const { clientTokenForAlert } = useSelector(selectCommonDetails);

  useEffect(() => {
    if (
      (userDetails && Object.keys(userDetails).length) ||
      userData?.user?.id
    ) {
      const data = {
        name: `${userData?.user?.first_name || userDetails?.first_name} ${
          userData?.user?.last_name || userDetails?.last_name
        }`,
        avatar: userData?.user?.avatar_url
      };
      setUser(data);
    }
  }, [userDetails, userData]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const navigateTo = (item) => {
    switch (item?.id) {
      case 'reset-password':
        handleClose();
        logEvent(firebaseAnalytics, 'reset_password_popup_open');
        setShowResetPasswordDialog(true);
        break;
      case 'help-assistance':
        handleClose();
        const payload = {
          name: `${userData?.user?.first_name || userDetails?.first_name} ${
            userData?.user?.last_name || userDetails?.last_name
          }`,
          email: userData?.user?.email || userDetails?.email
        };
        logEvent(
          firebaseAnalytics,
          'help_assistance_open_on_logged_user',
          payload
        );
        setShowHelpandAssistanceDialog(true);
        break;
      case 'logout':
        userLogout();
        break;
      case 'delete-account':
        logEvent(firebaseAnalytics, 'delete_account_open_popup');
        handleClose();
        setShowDeleteAccount(true);
        break;
      default:
        break;
    }
  };

  const unSubscribeTopic = async () => {
    if (clientTokenForAlert) {
      try {
        setOpen(false);
        setShowBackdropLoader(true);
        const payload = {
          tokens: [clientTokenForAlert],
          is_app: false
        };
        await unsubscribeTopic(payload);
        dispatch(clearResults());
        setShowBackdropLoader(false);
      } catch (error) {
        console.log('The topic is not unsubscribed');
      }
    }
  };

  const userLogout = async () => {
    logEvent(firebaseAnalytics, 'logout');
    await unSubscribeTopic();
    logout();
  };

  const closeResetPasswordDialog = () => {
    dispatch(clearSetPasswordDetails());
    setShowResetPasswordDialog(false);
  };

  const closeDeleteAccountDialog = () => {
    setShowDeleteAccount(false);
  };

  const handleHelpAndAssistanceClose = () => {
    logEvent(firebaseAnalytics, 'help_assistance_on_logged_user');
    setShowHelpandAssistanceDialog(false);
  };

  return (
    <>
      <Box ref={ref} className="flex-basic-center">
        {user?.avatar && (
          <Box
            className="header-avatar-section"
            sx={{
              background: `url(${user?.avatar})`
            }}
          />
        )}
        {!user.avatar && (
          <Box className="user-box text-h3-regular">
            <Box className="icon-user" sx={{ p: 2 }} />
          </Box>
        )}
        <Box className="UserBoxText">
          <Typography className="UserBoxLabel" variant="body1">
            {user.name}
          </Typography>
        </Box>
        <IconButton onClick={handleOpen} sx={{ ml: 1 }}>
          <ExpandMoreTwoToneIcon />
        </IconButton>
      </Box>
      <Popover
        className={classes.popOverStyle}
        sx={{ mt: 11 }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box sx={{ m: 1 }}>
          {options?.map((item) => (
            <Box key={item?.id} sx={{ px: 1 }} onClick={() => navigateTo(item)}>
              <Box
                sx={{ py: 1, pr: 6 }}
                className="flex-basic-start text-large-regular cursorPointer"
              >
                <Box className={item.icon} sx={{ mr: 1 }} />
                <Box>{t(item?.label)}</Box>
              </Box>
              <Divider sx={{ mt: 1 }} />
            </Box>
          ))}
        </Box>
      </Popover>

      {showResetPasswordDialog && (
        <ResetPasswordDialog
          showResetPasswordDialog={showResetPasswordDialog}
          handleClose={closeResetPasswordDialog}
        />
      )}

      {showDeleteAccount && (
        <DeleteAccountDialog
          open={showDeleteAccount}
          onClose={closeDeleteAccountDialog}
          unSubscribeTopic={unSubscribeTopic}
        />
      )}

      <SimpleDialog
        open={showBackdropLoader}
        model_title={
          <Box sx={{ mt: 3 }} className="text-h3 textalign">
            Logout is in progress
          </Box>
        }
        model_content={
          <Box className="flex-column-center">
            <CircularProgress sx={{ mb: 3 }} />
          </Box>
        }
      />

      <HelpAndAssistance
        showHelpAndAssistance={showHelpandAssistanceDialog}
        name={`${userData?.user?.first_name || userDetails?.first_name} ${
          userData?.user?.last_name || userDetails?.last_name
        }`}
        email={userData?.user?.email || userDetails?.email}
        handleClose={handleHelpAndAssistanceClose}
      />
    </>
  );
};

export default HeaderUserbox;
