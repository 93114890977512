import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  onMessageListener,
  requestForToken
} from 'src/firebaseNotifications/firebase';
import { Box, IconButton } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { isMobile, isIOS } from 'react-device-detect';

import HeaderUserbox from './user-box/HeaderUserbox';
import HeaderButtons from './buttons/HeaderButtons';
import {
  getAlertList,
  selectCommonDetails,
  setClientTokenForAlert,
  setNewAlertFlag,
  subscribeToTopic
} from 'src/shared/services/common.service';
import { SidebarContext } from 'src/providers/SidebarContext';

const Header = () => {
  //Constants
  const dispatch = useDispatch<any>();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  //Redux
  const { topicSubscriptionDetails } = useSelector(selectCommonDetails);

  //State
  const [notificationPermission, setNotificationPermission] = useState<string>(
    isIOS ? '' : Notification.permission
  );

  //useEffects
  useEffect(() => {
    if (!isIOS && notificationPermission !== 'granted') {
      Notification.requestPermission().then((permission) => {
        setNotificationPermission(permission);
      });
    }
  }, []);

  useEffect(() => {
    if (
      !isIOS &&
      notificationPermission === 'granted' &&
      topicSubscriptionDetails &&
      !topicSubscriptionDetails?.message
    ) {
      requestForToken().then((token) => {
        if (token) {
          const payload = {
            tokens: [token]
          };
          dispatch(setClientTokenForAlert(token));
          subscribeToGetAlerts(payload);
        } else {
          console.log('Token is not available');
        }
      });
    } else {
      console.log(`Notification permission is ${notificationPermission}`);
    }
  }, [notificationPermission]);

  //Methods
  const subscribeToGetAlerts = async (apiPayload: { tokens: string[] }) => {
    dispatch(subscribeToTopic(apiPayload));
  };

  onMessageListener()
    .then((payload) => {
      dispatch(getAlertList());
      dispatch(setNewAlertFlag(true));
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <Box
      className="HeaderWrapper headerSection"
      display="flex"
      alignItems="center"
      justifyContent={isMobile ? 'space-between' : 'end'}
    >
      {isMobile && (
        <Box
          component="span"
          sx={{
            ml: 2
          }}
          className="userbox"
        >
          <IconButton color="primary" onClick={toggleSidebar}>
            {!sidebarToggle ? (
              <MenuTwoToneIcon fontSize="small" />
            ) : (
              <CloseTwoToneIcon fontSize="small" />
            )}
          </IconButton>
        </Box>
      )}
      <Box display="flex" alignItems="center">
        <HeaderButtons />
        <HeaderUserbox />
      </Box>
    </Box>
  );
};

export default Header;
