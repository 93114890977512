import { apiPost } from 'src/core/interceptors/axios-instance';
import { AUTH_SERVICE_URL } from 'src/shared/constants/constants';

export const userLogin = async (payload: Object) => {
  const response = await apiPost('api/user/login', AUTH_SERVICE_URL, payload);
  return response.data;
};

export const forgotPassword = async (payload: Object) => {
  const response = await apiPost('api/v1/user/forgot-password', null, payload);
  return response.data;
};

export const setPassword = async (payload: Object) => {
  const response = await apiPost(
    'api/user/set-password',
    AUTH_SERVICE_URL,
    payload
  );
  return response.data;
};

export const resetPassword = async (payload: Object) => {
  const response = await apiPost(
    'api/user/reset-password',
    AUTH_SERVICE_URL,
    payload
  );
  return response.data;
};
