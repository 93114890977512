import React from 'react';
import { TextField as MuiTextField } from '@mui/material';
import { TextFieldProps } from './services/textFieldInterface';

export const TextField = React.forwardRef<any, TextFieldProps>(
  ({ ...props }: TextFieldProps, ref: any) => {
    return (
      <MuiTextField
        {...props}
        ref={ref}
        onFocus={(event) => {
          event.target.setAttribute('autocomplete', 'new-password');
        }}
        InputLabelProps={{
          classes: {
            asterisk: 'custom-asterisk'
          }
        }}
      />
    );
  }
);
export default TextField;
