import DialogTitle from '@mui/material/DialogTitle';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { MuiDialog } from './services/modalInterface';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles({
  customDialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        borderRadius: 0,
        '& .MuiDialogTitle-root': {
          borderBottom: 'none'
        },
        '& .MuiDialogContent-root': {
          padding: '16px 24px'
        },
        '& .MuiDialogActions-root': {
          padding: '16px 24px'
        }
      }
    }
  }
});

export const SimpleDialog = ({ ...props }: MuiDialog) => {
  const {
    handleClose,
    selectedValue,
    open,
    model_title,
    model_content,
    model_actions,
    fullWidth,
    maxWidth
  } = props;
  const classes = useStyle();

  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      keepMounted
      onClose={handleClose}
      className={classes.customDialog}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{model_title}</DialogTitle>
      <DialogContent>{model_content}</DialogContent>
      {model_actions && <DialogActions>{model_actions}</DialogActions>}
    </Dialog>
  );
};
