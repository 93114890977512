import { FC, ReactNode, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet, useLocation } from 'react-router-dom';

import logo from '../assets/images/trusttap_logo.svg';

import { Box, Card, Container } from '@mui/material';
import Footer from './footer/Footer';

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.getElementById('root').scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container maxWidth={'lg'}>
      <Box className="baselayout">
        <Box sx={{ my: 3 }}>
          <img src={logo} alt="TrustTapLogo" />
        </Box>
        <Card sx={{ borderRadius: 0 }}>{children || <Outlet />}</Card>
        <Box sx={{ mt: 3 }}>
          <Footer />
        </Box>
        <Box sx={{ my: 3 }} className="flex-basic-space-between"></Box>
      </Box>
    </Container>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
