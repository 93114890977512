import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import reesendEmailWait from '../../../assets/images/resend-email-wait.svg';
import { useDispatch } from 'react-redux';
import { clearResendEmailDetails } from '../service/resendEmail.service';

const ResendEmailSuccess = () => {
  //Constants
  const { t } = useTranslation('english');
  const dispatch = useDispatch<any>();

  //useEffect
  useEffect(() => {
    dispatch(clearResendEmailDetails());
  }, []);

  return (
    <Box sx={{ p: isMobile ? 5 : 7 }} className="flex-column-center">
      <Box sx={{ mb: 5 }}>
        <img src={reesendEmailWait} alt="Resend Email" />
      </Box>
      <Box sx={{ mb: 15 }} className="text-h2 text-grey-2 textalign">
        {t('resendEmailSuccess.title')}
      </Box>
      <Box sx={{ mb: 5 }} className="text-h2 text-grey-2 textalign">
        {t('resendEmailSuccess.remember')}
      </Box>
      <Box sx={{ mb: 3 }} className="text-medium-regular text-grey-2 textalign">
        {t('resendEmailSuccess.point1')}
      </Box>
      <Box sx={{ mb: 5 }} className="text-medium-regular text-grey-2 textalign">
        {t('resendEmailSuccess.point2')}
      </Box>
    </Box>
  );
};

export default ResendEmailSuccess;
