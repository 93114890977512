import { Box } from '@mui/material';
import HeaderNotifications from './notifications/HeaderNotifications';

const HeaderButtons = () => {
  return (
    <Box sx={{ mr: 1 }} display="flex">
      <HeaderNotifications />
    </Box>
  );
};

export default HeaderButtons;
