import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

type CustomRouterProviderType = {
  routeTo: (
    path: string,
    isChild?: boolean,
    stateName?: string,
    query?: any,
    stateObj?: any
  ) => any;
  goBack: (step?: number) => any;
};

export const customRouterProvider = () => {
  const navigate = useNavigate();

  const createQueryParam = (isChild: boolean, query?: any) => {
    let queryParams = '?';
    if (query && Object.keys(query).length > 0) {
      queryParams = queryParams.concat(
        JSON.stringify(query)
          .replace(/{/g, '')
          .replace(/}/g, '')
          .replace(/\"/g, '')
          .replace(/:/g, '=')
          .replace(/,/g, '&')
      );
    }
    if (isChild) {
      queryParams = queryParams.concat('&isChild=true');
    }
    return queryParams;
  };

  const getStateObj = (stateName: string, stateObj: any) => {
    let state = {
      name: stateName
    };
    if (stateObj && Object.keys(stateObj).length > 0) {
      state = { ...stateObj, name: stateName };
    }
    return state;
  };

  const routeTo = (
    path: string,
    isChild?: boolean,
    stateName?: string,
    query?: any,
    stateObj?: any
  ) => {
    const isChildRoute = isChild || false;
    const customSateName = stateName || '';
    const queryParams = createQueryParam(isChildRoute, query);
    const state = getStateObj(customSateName, stateObj);
    const updatedPath = queryParams ? path.concat(queryParams) : path;
    navigate(updatedPath, { state: state });
  };

  const goBack = (step?: number) => {
    navigate(step || -1);
  };

  return {
    routeTo,
    goBack
  };
};

const defaultRouterContext = {};

const CustomRouterContext = createContext<CustomRouterProviderType>(
  defaultRouterContext as any
);

export const useRouter = () => {
  return useContext(CustomRouterContext);
};

export const CustomRouterProvider = ({ children }: { children: any }) => {
  const customRouter = customRouterProvider();
  return (
    <CustomRouterContext.Provider value={customRouter as any}>
      {children}
    </CustomRouterContext.Provider>
  );
};
