import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  datatable: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none !important'
    }
  }
});
export const Tables = ({
  rows,
  columns,
  checkboxSelection,
  rowHeight,
  localeText,
  hideFooterPagination = true,
  rowCount = 5
}) => {
  const classes = useStyles();
  return (
    <div className="tablestyle">
      <DataGrid
        rows={rows}
        rowHeight={rowHeight}
        columns={columns}
        checkboxSelection={checkboxSelection}
        disableColumnFilter={true}
        localeText={localeText}
        rowCount={rowCount}
        hideFooterPagination={hideFooterPagination}
        disableColumnMenu
        autoHeight={true}
        disableRowSelectionOnClick={true}
        disableDensitySelector={true}
        classes={{ root: classes.datatable }}
      />
    </div>
  );
};
