import { createContext, useContext, useState } from 'react';
import { AuthProviderType } from './models/AuthguardContextType';
import { useDispatch } from 'react-redux';
import { clearStorage } from 'src/shared/utils/storage';
import { clearResults } from 'src/modules/set-password/service/setPassword.service';
import { ACCESS_TOKEN } from 'src/shared/constants/constants';

export const useAuthProvider = () => {
  // Constants
  const dispatch = useDispatch();

  // State Values
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem(ACCESS_TOKEN) || ''
  );
  const [roles, setUserRoles] = useState(
    JSON.parse(localStorage.getItem('userRoles')) || ([] as string[])
  ); // TODO:: Update user roles on change of redux value

  // Methods
  const login = (authToken: string) => {
    setAccessToken(authToken);
  };
  const logout = () => {
    dispatch(clearResults());
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem('logged');
    setAccessToken('');
    clearStorage();
  };
  const setRoles = (userRoleList: string[]) => {
    setUserRoles(userRoleList);
  };

  return {
    accessToken,
    roles,
    login,
    logout,
    setRoles
  };
};
const defaultAuthContext = {};

const AuthContext = createContext<AuthProviderType>(defaultAuthContext as any);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const auth = useAuthProvider();
  return (
    <AuthContext.Provider value={auth as any}>{children}</AuthContext.Provider>
  );
};
