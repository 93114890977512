import React, { useEffect, useState } from 'react';
import { SimpleDialog } from '../modals/SimpleDialog';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import z from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  Button,
  CircularProgress,
  TextField,
  showErrorMessage,
  showSuccessMessage
} from '../index';
import { helpAndAssistance } from 'src/shared/apis/commonApis';
import { titleCase } from 'src/shared/utils/utils';

interface HelpAndAssistanceProps {
  showHelpAndAssistance: boolean;
  handleClose: () => void;
  name?: string;
  email?: string;
}

const HelpAndAssistance = ({
  showHelpAndAssistance,
  name,
  email,
  handleClose
}: HelpAndAssistanceProps) => {
  //Constants
  const { t } = useTranslation('english');

  //Zod Schema
  const helpAndAssistanceFormSchema = z.object({
    name: z
      .string()
      .min(1, { message: t('helpAndAssistance.nameRequiredErrMsg') }),
    sender_email: z
      .string()
      .email({ message: t('invalidEmailErrMsg') })
      .min(1, { message: t('emailRequiredErrMsg') }),
    description: z
      .string()
      .min(1, {
        message: t('helpAndAssistance.descriptionRequiredErrMsg')
      })
      .max(500, {
        message: t('helpAndAssistance.descriptionMaxCharLengthErrMsg', {
          maxLength: 500
        })
      })
  });

  type HelpAndAssistanceFormSchemaPayload = z.infer<
    typeof helpAndAssistanceFormSchema
  >;

  //Form Variables
  const { control, formState, handleSubmit, setValue, reset } =
    useForm<HelpAndAssistanceFormSchemaPayload>({
      resolver: zodResolver(helpAndAssistanceFormSchema),
      mode: 'onChange'
    });
  const { errors, isValid } = formState;

  //State
  const [loading, setLoading] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState('');

  //useEffects
  useEffect(() => {
    if (name) {
      setValue('name', name, { shouldValidate: true });
    }
    if (email) {
      setValue('sender_email', email, { shouldValidate: true });
    }
  }, []);

  //Methods
  const onSubmit = (formData: HelpAndAssistanceFormSchemaPayload) => {
    const helpAndAssistancePayload: HelpAndAssistanceFormSchemaPayload = {
      name: formData?.name
        ?.split(' ')
        ?.map((item) => titleCase(item?.trim()))
        ?.join(' '),
      sender_email: formData?.sender_email?.toLowerCase(),
      description: formData?.description
    };
    sendHelpAndAssistanceQuery(helpAndAssistancePayload);
  };

  const sendHelpAndAssistanceQuery = async (
    payload: HelpAndAssistanceFormSchemaPayload
  ) => {
    try {
      setLoading(true);
      const result = await helpAndAssistance(payload);
      if (result && result?.message) {
        closeHelpAndAssistanceDialog();
        showSuccessMessage(result?.message, { theme: 'colored' });
      }
    } catch (error) {
      showErrorMessage(error, { theme: 'colored' });
    }
    setLoading(false);
  };

  const handleDescriptionChange = (event) => {
    if (event?.target?.value?.length > 500) {
      return;
    }
    if (/^\s*$/.test(event?.target?.value)) {
      setDescriptionValue(event?.target?.value?.trim());
      setValue('description', event?.target?.value?.trim(), {
        shouldValidate: true
      });
    } else {
      setDescriptionValue(event?.target?.value);
      setValue('description', event?.target?.value, { shouldValidate: true });
    }
  };

  const closeHelpAndAssistanceDialog = () => {
    reset();
    setDescriptionValue('');
    handleClose();
  };

  //HTML
  return (
    <>
      <SimpleDialog
        maxWidth={'sm'}
        fullWidth
        open={showHelpAndAssistance}
        model_title={
          <Box className="flex-basic-space-between">
            <Box className="text-h3">{t('helpAndAssistanceTitle')}</Box>
            <CloseIcon
              className="cursorPointer"
              fontSize="small"
              onClick={closeHelpAndAssistanceDialog}
            />
          </Box>
        }
        model_content={
          <form>
            <Box className="flex-column-center align-items-start">
              <Box sx={{ mb: 2 }} className="w-100">
                <Controller
                  name="name"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('name')}
                      type={'text'}
                      variant="outlined"
                      required={true}
                      disabled={!!name}
                      inputProps={{ style: { textTransform: 'capitalize' } }}
                      error={Boolean(errors?.name)}
                      helperText={errors?.name?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }} className="w-100">
                <Controller
                  name="sender_email"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('emailAddress')}
                      type={'email'}
                      variant="outlined"
                      required={true}
                      disabled={!!email}
                      error={Boolean(errors?.sender_email)}
                      helperText={errors?.sender_email?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 5 }} className="w-100">
                <Controller
                  name="description"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t('addComment')}
                      variant="outlined"
                      multiline
                      minRows={4}
                      required={true}
                      error={Boolean(errors?.description)}
                      helperText={errors?.description?.message}
                      onChange={handleDescriptionChange}
                    />
                  )}
                />
                <Box className=" w-100 flex-basic-end text-grey-3 text-small-medium">
                  <Box>{`${descriptionValue?.length} / 500 character(s)`}</Box>
                </Box>
              </Box>
            </Box>
            <Box className="flex-column-center">
              {loading ? (
                <CircularProgress sx={{ mb: 2 }} color="primary" />
              ) : (
                <Button
                  sx={{ mb: 1 }}
                  width="button-w-280"
                  btnText={t('send')}
                  disabled={!isValid}
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                />
              )}
            </Box>
          </form>
        }
      />
    </>
  );
};

export default HelpAndAssistance;
