import { useEffect, useRef, useState } from 'react';
import { Box, Divider, List, ListItem, Popover, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { CircularProgress, Typography } from 'src/shared/components/index';
import {
  getAlertList,
  selectCommonDetails,
  setNewAlertFlag
} from 'src/shared/services/common.service';
import { AlertDetails } from 'src/shared/models/commonInterface';
import { dateFrom, getDateInShort } from 'src/shared/utils/utils';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'src/providers/RouterProvider';
import * as ROUTES from '../../../../shared/constants/routes';
import moment from 'moment';
import { setAlertJobSearchPreferrence } from 'src/modules/jobs/service/jobs.service';
import { logEvent } from 'firebase/analytics';
import { firebaseAnalytics } from 'src/firebaseNotifications/firebase';

const useStyle = makeStyles({
  customAlert: {
    '& .MuiPaper-root': {
      boarderRadius: 0
    }
  }
});

const HeaderNotifications = () => {
  //Constants
  const ref = useRef<HTMLInputElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const classes = useStyle();
  const { t } = useTranslation('english');
  const { routeTo } = useRouter();

  //Redux
  const { alertDetails, newAlertFlag, loading } =
    useSelector(selectCommonDetails);

  //State
  const [alertList, setAlertList] = useState<AlertDetails[]>([]);
  const [isNewAlert, setNewAlert] = useState(false);

  useEffect(() => {
    dispatch(getAlertList());
  }, []);

  useEffect(() => {
    if (alertDetails?.alerts?.length) {
      dispatch(setNewAlertFlag(true));
      setAlertList(alertDetails?.alerts);
    }
  }, [alertDetails]);

  useEffect(() => {
    setNewAlert(newAlertFlag);
  }, [newAlertFlag]);

  const handleOpen = (): void => {
    logEvent(firebaseAnalytics, 'job_tab_click');
    dispatch(setNewAlertFlag(false));
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const getDate = (dateTime: any) => {
    const date = dateFrom(dateTime);
    const result = getDateInShort(date);
    return result;
  };

  const handleViewJobs = (alertData: AlertDetails) => {
    logEvent(firebaseAnalytics, 'job_alert_click', alertData);
    handleClose();
    dispatch(setAlertJobSearchPreferrence(alertData));
    routeTo(ROUTES.JOBS);
  };

  //HTML
  return (
    <Box ref={ref}>
      <Tooltip arrow title="Alerts">
        <Box
          sx={{ mr: 3, p: 1 }}
          onClick={handleOpen}
          className={clsx('cursorPointer alerts-icon-container', {
            ['notification-badge']: isNewAlert,
            ['notification-active']: isOpen
          })}
        >
          <Box className="icon-notification" />
        </Box>
      </Tooltip>
      <Popover
        sx={{ mt: 9 }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        className={'customAlert'}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box className="text-h2 text-grey-2">Alerts</Box>
          <CloseIcon
            fontSize="small"
            onClick={handleClose}
            className="cursorPointer"
          />
        </Box>
        <List sx={{ p: 0 }} className="alert-list">
          {loading ? (
            <Box className="flex-basic-center">
              <CircularProgress sx={{ my: 3 }} color="primary" />
            </Box>
          ) : alertList?.length > 0 ? (
            alertList
              ?.slice()
              .reverse()
              .map((alert, index) => (
                <Box key={index}>
                  <ListItem className="m-width-Userbox" sx={{ p: 2 }}>
                    <Box flex="1">
                      <Box display="flex" justifyContent="space-between">
                        <Box
                          sx={{ mr: 3 }}
                          className="text-small-regular text-gray-2"
                        >
                          {alert?.message}
                        </Box>
                        <Typography variant="caption" className="textNone">
                          {getDate(alert?.created_at)}
                        </Typography>
                      </Box>
                      {alert?.is_job_alert && (
                        <Box sx={{ m: 1, ml: 0 }}>
                          <span
                            className="cursorPointer viewJobs"
                            onClick={() => handleViewJobs(alert)}
                          >
                            {t('alerts.viewJobs')}
                          </span>
                        </Box>
                      )}
                    </Box>
                  </ListItem>
                  {index + 1 !== alertList.length && (
                    <Divider className="text-grey-5" />
                  )}
                </Box>
              ))
          ) : (
            <Box sx={{ my: 3 }} className="text-large-medium textalign">
              {t('alerts.noAlertsMsg')}
            </Box>
          )}
        </List>
      </Popover>
    </Box>
  );
};

export default HeaderNotifications;
