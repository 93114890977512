import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store/reducer';
import { searchJobs } from '../api/jobsApi';
import { JobsListingType } from '../models/jobs';
import { AlertDetails } from 'src/shared/models/commonInterface';

interface JobsService {
  loading: boolean;
  error: boolean;
  errorObj: Object;
  jobList: JobsListingType[];
  viewOrientation: string;
  alertJobSearchPreferrence: AlertDetails;
}

const initialState: JobsService = {
  loading: false,
  error: false,
  errorObj: {},
  jobList: [] as JobsListingType[],
  viewOrientation: 'grid',
  alertJobSearchPreferrence: <AlertDetails>{}
};

export const selectJobsDetails = ({ jobsData }: RootState) => ({
  loading: jobsData?.loading,
  error: jobsData?.error,
  jobList: jobsData?.jobList,
  viewOrientation: jobsData?.viewOrientation,
  alertJobSearchPreferrence: jobsData?.alertJobSearchPreferrence
});

export const getJobsList = createAsyncThunk(
  'jobs/getJobsList',
  async (payload: Object) => {
    const result = await searchJobs(payload);
    return result;
  }
);

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setViewOrientation: (state, action) => {
      state.viewOrientation = action.payload;
    },
    setAlertJobSearchPreferrence: (state, action) => {
      state.alertJobSearchPreferrence = action.payload;
    },
    setJobsDetailList: (state, action) => {
      state.jobList = action?.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobsList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getJobsList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.jobList = action.payload?.jobs;
        }
      })
      .addCase(getJobsList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  }
});

export const {
  setViewOrientation,
  setAlertJobSearchPreferrence,
  setJobsDetailList
} = jobsSlice.actions;

export default jobsSlice.reducer;
