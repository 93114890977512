import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store/reducer';
import { ResumeResultType, UserDetailsResultType } from '../models/dashboard';
import { getResume, getUserData } from '../api/dashboard';

interface ResendEmailDetails {
  loading: boolean;
  error: boolean;
  errorObj: Object;
  userDataLoading: boolean;
  userDataError: boolean;
  userData: UserDetailsResultType;
  resumeData: ResumeResultType;
}

const initialState: ResendEmailDetails = {
  loading: false,
  userDataLoading: false,
  userDataError: false,
  error: false,
  errorObj: {},
  userData: <UserDetailsResultType>{},
  resumeData: <ResumeResultType>{}
};

export const selectDashboard = ({ dashboard }: RootState) => ({
  loading: dashboard?.loading,
  error: dashboard?.error,
  userData: dashboard?.userData,
  resumeData: dashboard?.resumeData,
  userDataLoading: dashboard?.userDataLoading,
  userDataError: dashboard?.userDataError
});

export const getUserDetails = createAsyncThunk(
  'dashboard/getUserDetails',
  async () => {
    const result = await getUserData();
    return result;
  }
);

export const getUserResume = createAsyncThunk(
  'dashboard/getUserResume',
  async () => {
    const result = await getResume();
    return result;
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetUserData: (state) => {
      state.userData = {} as UserDetailsResultType;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.userDataLoading = true;
        state.userDataError = false;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.userDataLoading = false;
        state.userDataError = false;
        if (action.payload) {
          state.userData = action.payload;
        }
      })
      .addCase(getUserDetails.rejected, (state) => {
        state.userDataLoading = false;
        state.userDataError = true;
      })
      .addCase(getUserResume.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUserResume.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.resumeData = action.payload;
        }
      })
      .addCase(getUserResume.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  }
});

export const { resetUserData } = dashboardSlice.actions;

export default dashboardSlice.reducer;
