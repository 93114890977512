import { FC, ReactNode, useEffect } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../header/Header';
import Sidebar from '../side-bar/Sidebar';
import Footer from '../footer/Footer';
import trustTapCircle from '../../assets/images/trust_tap_circle.png';
import { useTranslation } from 'react-i18next';
import { Button } from '../../shared/components/index';
import { TRUST_TAP_APP_URL } from 'src/shared/constants/constants';
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCommonDetails,
  setShowMobileAppBanner
} from 'src/shared/services/common.service';
import { logEvent } from 'firebase/analytics';
import { firebaseAnalytics } from 'src/firebaseNotifications/firebase';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const { t } = useTranslation('english');
  const dispatch = useDispatch<any>();
  const { pathname } = useLocation();

  useEffect(() => {
    document.getElementById('root').scrollTo(0, 0);
  }, [pathname]);

  //Redux
  const { showMobileAppBanner } = useSelector(selectCommonDetails);

  // Methods
  const navigateTo = () => {
    logEvent(firebaseAnalytics, 'download_app');
    window.open(TRUST_TAP_APP_URL, '_blank');
  };

  const handleCloseBanner = () => {
    dispatch(setShowMobileAppBanner(false));
  };

  return (
    <>
      <Box
        className={
          theme.colors.error.main === 'dark'
            ? 'layoutbox MuiPageTitlewrapperDark '
            : 'layoutbox MuiPageTitlewrapperLight '
        }
      >
        <Header />
        <Sidebar />
        <Box
          className="layout"
          sx={{
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('sm')]: {
              ml: `${theme.sidebar.width}`
            }
          }}
        >
          {isMobile && showMobileAppBanner && (
            <Box
              sx={{ py: 2, px: 1, mt: 4 }}
              className="mobileAppBanner flex-basic-space-between"
            >
              <Grid container>
                <Grid item xs={3} className="flex-basic-center">
                  <Box className="">
                    <img src={trustTapCircle} />
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box className="flex-column-start align-items-start">
                    <Box className="text-medium-semibold">
                      {`${t('sidebar.download_app_message1')} 
                    ${t('sidebar.download_app_message2')}`}
                    </Box>
                    <Box sx={{ mt: 1 }} className="get-our-app-section">
                      <Button
                        btnText={t('sidebar.get_our_app')}
                        variant="outlined"
                        onClick={navigateTo}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box className="flex-basic-center">
                    <CloseIcon
                      className="cursorPointer"
                      onClick={handleCloseBanner}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box
            sx={{ py: showMobileAppBanner && isMobile ? 5 : 8 }}
            display="block"
          >
            <Outlet />
            <Box sx={{ mt: 3, mx: 2 }}>
              <Footer />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
