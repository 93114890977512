import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store/reducer';
import { resendEmail } from '../api/resendEmail';
import { ResendEmailDetail } from '../model/resendEmailInterface';

interface ResendEmailDetails {
  loading: boolean;
  error: boolean;
  errorObj: Object;
  resendEmailDetails: ResendEmailDetail;
}

const initialState: ResendEmailDetails = {
  loading: false,
  error: false,
  errorObj: {},
  resendEmailDetails: <ResendEmailDetail>{}
};

export const selectResendEmailDetail = ({ resendEmailData }: RootState) => ({
  loading: resendEmailData?.loading,
  error: resendEmailData?.error,
  resendEmailDetails: resendEmailData?.resendEmailDetails
});

export const resendOnboardingEmail = createAsyncThunk(
  'resendEmail/resendOnboardingEmail',
  async (payload: Object) => {
    const result = await resendEmail(payload);
    return result;
  }
);

export const resendEmailSlice = createSlice({
  name: 'resendEmail',
  initialState,
  reducers: {
    clearResendEmailDetails: (state) => {
      state.resendEmailDetails = {} as ResendEmailDetail;
    },
    resetState: (state) => {
      state.loading = false;
      state.error = false;
      state.errorObj = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(resendOnboardingEmail.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(resendOnboardingEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.resendEmailDetails = action.payload;
        }
      })
      .addCase(resendOnboardingEmail.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  }
});

export const { resetState, clearResendEmailDetails } = resendEmailSlice.actions;

export default resendEmailSlice.reducer;
