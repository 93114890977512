import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import OnboardingForm from './components/OnboardingForm';
import { toast } from 'react-toastify';
import OnboardingSuccess from './components/OnboardingSuccess';
import { isMobile } from 'react-device-detect';

const OnboardingScreen = () => {
  //Constants
  const { t } = useTranslation('english');
  const [isOnbardingCompleted, setIsOnboardingCompleted] =
    useState<boolean>(false);

  //Methods
  const getOnboardingStatus = (isSubmittedSuccessfully: boolean) => {
    if (isSubmittedSuccessfully) {
      setIsOnboardingCompleted(isSubmittedSuccessfully);
    } else {
      toast('Something went wrong, please try again later.');
    }
  };

  //HTML
  return (
    <>
      {isOnbardingCompleted ? (
        <OnboardingSuccess />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <OnboardingForm getOnboardingStatus={getOnboardingStatus} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box className="flex-column-center onboarding-right-section">
              <Box
                sx={{ mb: 8, px: 10, pt: isMobile ? 10 : 0 }}
                className="textalign text-h3"
              >
                {t('onboarding.rightSectionTitle')}
              </Box>
              <Box sx={{ mx: isMobile ? 5 : 8, mt: 5 }}>
                <Box sx={{ mb: 5 }} className="text-large-medium">
                  {t('onboarding.point1BeforeBold')}
                  <span style={{ fontWeight: 'bold' }}>
                    {t('onboarding.point1Bold')}
                  </span>
                  {t('onboarding.point1AfterBold')}
                </Box>
                <Box sx={{ mb: 5 }} className="text-large-medium">
                  {t('onboarding.point2')}
                  <span style={{ fontWeight: 'bold' }}>
                    {t('onboarding.point2Bold')}
                  </span>
                </Box>
                <Box sx={{ mb: 5 }} className="text-large-medium">
                  {t('onboarding.point3')}
                  <span style={{ fontWeight: 'bold' }}>
                    {t('onboarding.point3Bold')}
                  </span>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default OnboardingScreen;
