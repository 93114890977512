import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { onboardUser } from '../api/onboardingApi';
import { OnbardingApiResponse } from '../models/onboardingInterface';
import { showErrorMessage } from '../../../shared/components';
import { RootState } from 'src/store/reducer';

interface OnboardingDetails {
  loading: boolean;
  error: boolean;
  errorObj: Object;
  onboardingDetail: OnbardingApiResponse;
}

const initialState: OnboardingDetails = {
  loading: false,
  error: false,
  errorObj: {},
  onboardingDetail: <OnbardingApiResponse>{}
};

export const selectOnboardingDetail = ({ onboardingData }: RootState) => ({
  loading: onboardingData?.loading,
  error: onboardingData?.error,
  errorObj: onboardingData?.errorObj,
  onboardingDetail: onboardingData?.onboardingDetail
});

export const completeOnboarding = createAsyncThunk(
  'onboarding/completeOnboarding',
  async (payload: Object) => {
    const result = await onboardUser(payload);
    return result;
  }
);

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    clearOnboardingDetails: (state) => {
      state.onboardingDetail = {} as OnbardingApiResponse;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(completeOnboarding.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(completeOnboarding.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.onboardingDetail = action.payload;
        }
      })
      .addCase(completeOnboarding.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
  }
});

export const { clearOnboardingDetails } = onboardingSlice.actions;

export default onboardingSlice.reducer;
