import { Box } from '@mui/material';
import { SidebarMenus } from 'src/shared/components/index';
import * as ROUTES from 'src/shared/constants/routes';

const SidebarMenu = () => {
  return (
    <>
      <SidebarMenus
        options={[
          {
            id: 0,
            pathname: ROUTES.DASHBOARD,
            icon: <Box className="icon-dashboard text-large-regular" />,
            title: 'sidebar.home',
            tab: 'home'
          },
          {
            id: 1,
            pathname: ROUTES.JOBS,
            icon: <Box className="icon-briefcase text-large-regular" />,
            title: 'sidebar.jobs',
            tab: 'jobs'
          }
        ]}
      />
    </>
  );
};

export default SidebarMenu;
