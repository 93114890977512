import { apiPost } from 'src/core/interceptors/axios-instance';

export const searchJobs = async (payload: Object) => {
  const response = await apiPost('api/v1/jobs', null, payload);
  return response.data;
};

export const applyJobs = async (payload: Object) => {
  const response = await apiPost('api/v1/jobs/apply', null, payload);
  return response.data;
};
