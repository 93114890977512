import { apiGet, apiPost } from 'src/core/interceptors/axios-instance';
import { apiGetS3 } from 'src/core/interceptors/axios-instance-s3';

export const subscribeTopic = async (payload: Object) => {
  const response = await apiPost('api/v1/alert/subscribe', null, payload);
  return response.data;
};

export const unsubscribeTopic = async (payload: Object) => {
  const response = await apiPost('api/v1/alert/unsubscribe', null, payload);
  return response.data;
};

export const getAlerts = async () => {
  const response = await apiGet('api/v1/alert');
  return response.data;
};

export const getIp = async () => {
  const response = await apiGetS3('https://api.ipify.org/?format=json');
  return response.data;
};

export const helpAndAssistance = async (payload: Object) => {
  const response = await apiPost('api/v1/help-assist', null, payload);
  return response.data;
};
